<template>
  <div v-if="filterTabs">
    <CrudIndex
      v-bind="this.$store.state.stone_model.page_cover"
      :filterTabs="filterTabs"
    ></CrudIndex>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filterTabs: null,
    };
  },
  mounted() {
    this.$_setFilterTabs();
  },
  methods: {
    async $_setFilterTabs() {
      const res = await this.$axios.get(`/page_setting`);
      const _filterTabs = [
        {
          text: this.$t("全部"),
          value: "all",
          params: {},
        },
      ];
      res.data.data.forEach((item) => {
        _filterTabs.push({
          text: item.name,
          value: item.id,
          params: {
            page_settings: item.id,
          },
        });
      });
      this.filterTabs = _filterTabs;
    },
  },
};
</script>